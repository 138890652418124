@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .wrapper {
    @apply flex flex-row;
    @apply 2xl:mt-13;
    @apply 4xl:mt-16;
  }

  .imageContainer {
    @apply relative;
    @apply 2xl:ml-20;
    @apply 4xl:ml-44;
  }

  .image {
    @apply 2xl:h-134 2xl:w-191 2xl:rounded-1.875rem;
    @apply 4xl:h-265 4xl:w-378 4xl:rounded-4.5rem;
  }

  .textContainer {
    @apply overflow-hidden;
    @apply 2xl:mr-18 2xl:ml-23;
    @apply 4xl:mr-44 4xl:ml-40;
  }

  .generalTitleSubtitleImageTextHeader {
    @apply font-bebas-bold uppercase text-white;
    @apply 2xl:text-5.625rem 2xl:leading-6.25rem 2xl:tracking-tight 2xl:mb-10;
    @apply 4xl:text-9xl 4xl:leading-less-extra-loose 4xl:tracking-wider 4xl:mb-20;
  }

  .generalTitleSubtitleImageText {
    @apply font-bebas-regular uppercase text-white;
    @apply 2xl:text-5.625rem 2xl:leading-6.25rem 2xl:tracking-tight;
    @apply 4xl:text-9xl 4xl:leading-less-extra-loose 4xl:tracking-wider;
  }

  .dash {
    @apply bg-white;
    @apply 2xl:mt-10 2xl:h-4 2xl:w-20;
    @apply 4xl:mt-10 4xl:h-8 4xl:w-40;
  }
}

.textSlide {
  transform: translateX(-130%);
  animation: slide 0.8s linear 0.8s forwards;
}

@keyframes slide {
  0% {
    transform: translateX(-130%)
  }
  100% {
    transform: translateX(0)
  }
}

.imageReveal {
  position: absolute; 
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  animation: reveal 0.8s linear 0.4s forwards;
}

@keyframes reveal {
  0% {
    transform: translateY(0%)
  }
  100% {
    transform: translateY(100%)
  }
}