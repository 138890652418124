@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .header {
    @apply xl:pt-16 xl:ml-22;
    @apply 2xl:pt-32 2xl:ml-45;
  }

  .headerText {
    @apply font-bebas-bold uppercase;
    @apply xl:text-6.25rem xl:tracking-normal xl:h-25 xl:leading-6.25rem;
    @apply 2xl:text-12.5rem 2xl:tracking-widest 2xl:h-50 2xl:leading-loose;
  }
}

.headerTextReveal {
  position: relative;
  overflow: hidden;
}

.headerTextReveal::before,
.headerTextReveal::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: black;
}

.headerTextReveal::before {
  animation: reveal 1.5s ease forwards;
}
.headerTextReveal::after {
  animation: base 1.5s ease forwards;
}

@keyframes reveal {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(111%);
  }
}

@keyframes base {
  0% {
    transform: translateX(-0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.headerTextSlide {
  transform: translateX(-100%);
  animation: slide 0.5s linear forwards;
}

@keyframes slide {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0)
  }
}