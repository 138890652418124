@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .answerBox {
    @apply flex flex-row z-negative rounded-round bg-trivia-answer shadow-2xl-answer border border-gray-border;
    @apply xl:mb-7 xl:pl-8 xl:h-37;
    @apply 2xl:mb-16 2xl:pl-18 2xl:h-[18.375rem];
  }
  .letterBox {
    @apply rounded-round bg-white shadow-trivia-answer-letter;
    @apply xl:mt-5 xl:mr-21 xl:h-26 xl:w-26;
    @apply 2xl:mt-10 2xl:mr-40 2xl:h-52 2xl:w-52;
  }
  .letterText {
    @apply font-bebas-regular leading-relaxed text-black;
    @apply xl:mt-5 xl:ml-9 xl:text-5rem xl:leading-normal;
    @apply 2xl:mt-14 2xl:ml-20 2xl:text-7.5rem 2xl:leading-relaxed;
  }
  .answerText {
    @apply font-bebas-regular text-white;
    @apply xl:mt-10 xl:text-4.75rem xl:leading-4.75rem;
    @apply 2xl:mt-19 2xl:text-7.5xl 2xl:leading-9.375rem;
  }
}

.animation {
  opacity: 1;
  transform: translateX(-110%);
  animation: slide linear forwards;
}

@keyframes slide {
  0% {
    transform: translateX(-110%)
  }
  7% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(0)
  }
}

.animationFade {
  opacity: 1;
  transform: translateX(-110%);
  animation: fade linear forwards;
}

@keyframes fade {
  0% {
    transform: translateX(-110%)
  }
  7% {
    transform: translateX(0)
  }
  80% {
    opacity: 1;
    transform: translateX(0)
  }
  100% {
    opacity: 0.3;
    transform: translateX(0)
  }
} 

