@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .whiteBackground {
    background: white;
    height: 100%;
  }
  .blackBackground {
    background: black;
    height: 100%;
  }
  .wrapper {
    @apply lg:p-8 2160p:p-15;
  }
  .firstBoxLight {
    @apply h-350 2160p:h-520;
    border: 1px solid black;
    border-radius: 30px;
    box-shadow: 10px 10px 10px #d3d3d3;
    overflow: hidden;
  }
  .firstBoxDark {
    @apply h-350 2160p:h-520;
    border: 1px solid #707070;
    border-radius: 30px;
    overflow: hidden;
    background-color: #222222;
  }
  .backgroungGradient {
    @apply h-88 2160p:h-144;
    background: linear-gradient(180deg, #56f56e 0%, #6ead2500 100%) 0% 0%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0.49;
  }

  .imageContainer {
    @apply relative;
  }

  .image {
    @apply lg:h-114;
    @apply 2xl:h-209 2160p:h-222;
    display: flex;
    justify-content: center;
  }
  .headImage {
    @apply lg:mt-15;
    object-fit: contain;
  }

  .textContainer {
    @apply 1080p:h-96 1350p:h-80 2160p:mt-8;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .tipsTextLight {
    @apply font-bebas-regular uppercase text-black;
    @apply xl:mt-18 1080p:px-13 1350p:px-41 xl:text-6.25rem xl:leading-relaxed xl:tracking-wide;
    @apply 2xl:px-50 2xl:leading-extra-loose  2xl:tracking-wider 2160p:text-11.25rem;
  }
  .tipsTextDark {
    @apply font-bebas-regular uppercase text-white;
    @apply xl:mt-18 1080p:px-13 1350p:px-41 xl:text-6.25rem xl:leading-relaxed xl:tracking-wide;
    @apply 2xl:px-50 2xl:leading-loose 2160p:text-11.25rem;
  }

  .textSlide {
    animation: 3s ease 0s normal forwards 1 fadeIn;
  }

  .logoContainer {
    @apply lg:h-75;
    @apply 1080p:mt-30 1350p:mt-54;
    @apply 2160p:mt-180;
    display: flex;
    justify-content: center;
    transform: translateX(-100%);
    animation: slide 0.5s linear 1.5s forwards;
  }
  .logoImage {
    @apply lg:w-209;
    @apply 2160p:h-144 2160p:w-full;
    object-fit: contain;
  }

  .secondBoxLight {
    @apply h-56 mt-6 p-8;
    @apply 2160p:h-90 2160p:mt-15;
    border: 1px solid black;
    border-radius: 30px;
    box-shadow: 10px 10px 10px #d3d3d3;
    overflow: hidden;
  }
  .secondBoxDark {
    @apply h-58 mt-6 p-8;
    @apply 2160p:h-90 2160p:mt-15;
    border: 1px solid #707070;
    border-radius: 30px;
    overflow: hidden;
    background-color: #222222;
  }

  .bottomLogos {
    @apply h-45 justify-between;
    @apply 2160p:px-17;
    display: flex;
    animation: 4s ease 0s normal forwards 1 fadeInLogos;
  }
  .sponsorLogo1 {
    @apply w-88;
    @apply 2160p:h-88 2160p:w-fit;
    object-fit: contain;
  }

  .sponsorLogo2 {
    @apply w-96;
    @apply 2160p:h-66 2160p:w-fit;
    object-fit: contain;
  }

  .imageReveal {
    @apply 2160p:mt-8;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    animation: zoom-in 1.5s ease;
  }

  img[src=""] {
    display: none;
 }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes slide {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes zoom-in {
    0% {
      transform: scale(0, 0);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  @keyframes fadeInLogos {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
