
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  @layer components {
  .comparisonBoxContainer {
    @apply flex flex-row justify-center items-center;
    @apply 3xl:rounded-4xl 3xl:p-6 3xl:w-[930px] 3xl:h-[340px];
    @apply 4xl:rounded-[6rem] 4xl:p-8 4xl:h-fit;
    @apply 3840p:rounded-[6rem] 3840p:p-10 3840p:h-fit;

  }
  .titleText {
    @apply text-white font-bebas-bold whitespace-nowrap;
    @apply 3xl:text-[4.125rem] 3xl:tracking-[0.206rem];
    @apply 4xl:text-[7.25rem] 3xl:tracking-[0.3rem];
    @apply 4xl:text-[8.25rem] 3xl:tracking-[0.406rem];
  }
  .arrowContainer {
    @apply items-center;
    @apply xl:w-[132px] h-[169px];
    @apply 4xl:w-[271px] 4xl:h-fit;

  }
  .percentageText {
    @apply text-white font-bebas-bold;
    @apply 3xl:text-[221px] 3xl:tracking-[0.691rem];
    @apply 4xl:text-[300px] 4xl:tracking-[20px];
    @apply 3840p:text-[400px] 3840p:tracking-[20px];


  }
  .border {
    @apply border-[0px] items-center;
    @apply 3xl:h-[232px] 3xl:w-1 3xl:mr-10;
    @apply 4xl:h-[400px] 4xl:w-3 4xl:mr-10;
    @apply 3840p:h-[400px] 3840p:w-3 3840p:mr-20;


  }
  .leftColumn {
    @apply flex basis-2/3 justify-center items-center; 
  }
  .rightColumn {
    @apply flex flex-col basis-1/3 items-center justify-center;
  }
}
.slideAnimation {
  transform: translateX(-110%);
  opacity: 0;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
  animation: slideRight 1.5s linear 0.4s forwards;
  animation-delay: 2s;
}
@keyframes slideRight {
  0% {
    transform: translateX(-110%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}