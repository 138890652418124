@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .questionCard {
    @apply flex flex-row justify-between overflow-hidden bg-white;
    @apply 2xl:pl-20 2xl:mt-10 2xl:mx-20 2xl:rounded-2rem;
    @apply 4xl:pl-38 4xl:mt-22 4xl:mr-42 4xl:ml-44 4xl:rounded-3.75rem;
  }
  .questionText {
    @apply font-bebas-regular uppercase text-black;
    @apply 2xl:mt-20 2xl:text-5.625rem 2xl:tracking-tight 2xl:leading-6.75rem 2xl:w-209;
    @apply 4xl:mt-40 4xl:text-11.25rem 4xl:tracking-0.563rem 4xl:leading-13.5rem 4xl:w-420;
  }
  .answers {
    @apply overflow-hidden;
    @apply 2xl:pt-7 2xl:pr-21 2xl:pl-20;
    @apply 4xl:pt-16 4xl:pr-21 4xl:pl-20;
  }
}

.questionCardSlide {
  transform: translateX(-110%);
  animation: questionCardSlide 0.7s ease-out 0.5s forwards;
}

@keyframes questionCardSlide {
  0% {
    transform: translateX(-110%);
  }
  100% {
    transform: translateX(0);
  }
}
