@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .answerBox {
    @apply flex flex-row z-negative rounded-round bg-trivia-answer shadow-2xl-answer border border-gray-border;
    @apply 2xl:mb-6 2xl:pl-5 2xl:h-29 2xl:w-180;
    @apply 4xl:mb-12 4xl:pl-10 4xl:h-58 4xl:w-360;
  }
  .letterBox {
    @apply rounded-round shadow-trivia-answer-letter bg-white;
    @apply 2xl:mt-4 2xl:mr-18 2xl:h-21 2xl:w-21;
    @apply 4xl:mt-8 4xl:mr-38 4xl:h-41 4xl:w-41;
  }
  .letterText {
    @apply font-bebas-regular leading-relaxed text-black;
    @apply 2xl:mt-5 2xl:ml-7 2xl:text-3.75rem 2xl:leading-3.75rem;
    @apply 4xl:mt-10 4xl:ml-14 4xl:text-7.5rem 4xl:leading-relaxed;
  }
  .answerText {
    @apply font-bebas-regular text-white;
    @apply 2xl:mt-6 2xl:text-4.75rem 2xl:leading-4.75rem;
    @apply 4xl:mt-14 4xl:text-9.5rem 4xl:leading-9.5rem;
  }
}

.animation {
  opacity: 1;
  transform: translateX(110%);
  animation: slide linear forwards;
}

@keyframes slide {
  0% {
    transform: translateX(110%)
  }
  7% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(0)
  }
}

.animationFade {
  opacity: 1;
  transform: translateX(110%);
  animation: fade linear forwards;
}

@keyframes fade {
  0% {
    transform: translateX(110%)
  }
  7% {
    transform: translateX(0)
  }
  80% {
    opacity: 1;
    transform: translateX(0)
  }
  100% {
    opacity: 0.3;
    transform: translateX(0)
  }
} 

