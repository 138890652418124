@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .whiteBackground {
    background: white;
    height: 100%;
  }
  .blackBackground {
    background: black;
    height: 100%;
  }
  .wrapper {
    @apply lg:p-8 2160p:p-15;
    display: flex;
    flex-direction: row;
  }
  .firstBoxLight {
    @apply h-203 1080p:w-388 4xl:w-558 4xl:h-412;
    border: 1px solid black;
    border-radius: 30px;
    box-shadow: 10px 10px 10px #d3d3d3;
    overflow: hidden;
  }
  .firstBoxDark {
    @apply h-203 1080p:w-388 4xl:w-558 4xl:h-412;
    border: 1px solid #707070;
    border-radius: 30px;
    overflow: hidden;
    background-color: #222222;
  }
  .backgroungGradient {
    @apply h-45 2160p:h-144 4xl:h-90;
    background: linear-gradient(180deg, #56f56e 0%, #6ead2500 100%) 0% 0%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0.49;
  }

  .imageContainer {
    @apply relative;
  }

  .image {
    @apply 1080p:h-64;
    @apply 4xl:h-130;
    display: flex;
    justify-content: center;
  }
  .headImage {
    @apply lg:mt-4;
    object-fit: contain;
  }

  .textContainer {
    @apply 1080p:h-96 1350p:h-75 2160p:mt-8;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .tipsTextLight {
    @apply font-bebas-regular uppercase text-black;
    @apply xl:mt-10 1080p:px-13 1350p:px-41 xl:text-4.75rem;
    @apply 2xl:px-50 2xl:leading-slightly-relaxed  2xl:tracking-tight;
    @apply 4xl:px-50 4xl:text-7.5xl 4xl:leading-more-relaxed;
  }
  .tipsTextDark {
    @apply font-bebas-regular uppercase text-white;
    @apply xl:mt-10 1080p:px-13 1350p:px-41 xl:text-4.75rem;
    @apply 2xl:px-50 2xl:leading-slightly-relaxed 2xl:tracking-tight;
    @apply 4xl:px-50 4xl:text-7.5xl 4xl:leading-more-relaxed;
  }

  .textSlide {
    animation: 3s ease 0s normal forwards 1 fadeIn;
  }

  .logoContainer {
    @apply lg:h-52;
    display: flex;
    justify-content: center;
    transform: translateX(-100%);
    animation: slide 0.5s linear 1.5s forwards;
  }
  .logoImage {
    @apply lg:w-209;
    @apply 4xl:h-134 4xl:w-388 4xl:mt-44;
    object-fit: contain;
  }

  .secondBoxLight {
    @apply h-203 ml-6 p-2 w-66;
    @apply 4xl:w-142 4xl:h-412 4xl:ml-15;
    border: 1px solid black;
    border-radius: 30px;
    box-shadow: 10px 10px 10px #d3d3d3;
    overflow: hidden;
  }
  .secondBoxDark {
    @apply h-203 ml-6 p-2 w-66;
    @apply 4xl:w-142 4xl:h-412 4xl:ml-15;
    border: 1px solid #707070;
    border-radius: 30px;
    overflow: hidden;
    background-color: #222222;
  }

  .bottomLogos {
    @apply h-188 justify-end 4xl:h-410;
    display: flex;
    flex-direction: column;
    animation: 4s ease 0s normal forwards 1 fadeInLogos;
  }
  .sponsorLogo1 {
    @apply w-88;
    @apply 2160p:h-88 2160p:w-fit;
    object-fit: contain;
  }

  .sponsorLogo2 {
    @apply w-96;
    @apply 2160p:h-66 2160p:w-fit;
    object-fit: contain;
  }

  .imageReveal {
    @apply 2160p:mt-8;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    animation: zoom-in 1.5s ease;
  }

  img[src=""] {
    display: none;
 }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes slide {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes zoom-in {
    0% {
      transform: scale(0, 0);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  @keyframes fadeInLogos {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
