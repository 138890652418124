
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  @layer components {
  .comparisonBoxContainer {
    @apply flex flex-row justify-center items-center;
    @apply xl:rounded-[3.313rem] xl:p-8 xl:mt-[3.125rem] xl:h-[300px];
    @apply 1080p:rounded-[3.313rem] 1080p:p-12 1080p:mt-[3.125rem] 1080p:h-[399px];
    @apply 2xl:rounded-4xl 2xl:p-24 2xl:h-[759px] 2xl:my-56;
  }
  .titleText {
    @apply text-white font-bebas-bold whitespace-nowrap text-right;
    @apply xl:text-[4rem] xl:tracking-[0.2rem];
    @apply 1080p:text-[5.375rem] 1080p:tracking-[0.269rem];
    @apply 2xl:text-[10rem] 2xl:tracking-[0.5rem];
  }
  .arrowContainer {
    @apply items-center;
    @apply xl:w-[100px] xl:h-[150px] xl:mt-1;
    @apply 1080p:w-[144px] 1080p:h-[185px] 1080p:mt-1;
    @apply 2xl:w-[271px] 2xl:h-[347px] 2xl:mt-2; 

  }
  .percentageText {
    @apply text-white font-bebas-bold whitespace-nowrap text-left;
    @apply xl:text-[190px] xl:tracking-[0.5rem] text-center;
    @apply 1080p:text-[233px] 1080p:tracking-[0.728rem] 1080p:text-left 1080p:ml-5;
    @apply 2xl:text-[450px] 2xl:tracking-[1.406rem] 2xl:text-center;
  }
  .border {
    @apply items-center border-[0px];
    @apply xl:h-[220px] xl:w-1.5 xl:mr-[4rem] xl:ml-[2rem];
    @apply 1080p:h-[263px] 1080p:w-1.5 1080p:mr-[4rem] 1080p:ml-[0rem];
    @apply 2xl:h-[478px] 2xl:w-2 2xl:mr-[6rem];
  }
  .leftColumn {
    @apply flex flex-col basis-2/3 justify-center; 
  }
  .rightColumn {
    @apply flex flex-col basis-1/3 items-center justify-center; 
  }
}
.slideAnimation {
  transform: translateX(-110%);
  opacity: 0;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
  animation: slideRight 1.5s linear 0.4s forwards;
  animation-delay: 2s;
}
@keyframes slideRight {
  0% {
    transform: translateX(-110%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}