@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .footer {
    @apply absolute right-0 left-0 bg-white;
    @apply 2xl:bottom-12 2xl:mx-20 2xl:rounded-1.25rem;
    @apply 4xl:bottom-[4.5rem] 4xl:mx-44 4xl:rounded-3.5rem;
  }

  .columns {
    @apply flex flex-row;
    @apply 2xl:px-5 2xl:py-5;
    @apply 4xl:px-9 4xl:py-9;
  }

  .imageContainer {
    @apply w-[18vw];
  }

  .image {
    @apply 2xl:h-[9.75rem] 2xl:bg-9.75rem;
    @apply 4xl:h-[20.75rem] 4xl:bg-20.75rem;
  }

  .rightColumn {
    @apply flex flex-col;
    @apply 2xl:mt-4 2xl:ml-8;
    @apply 4xl:mt-6 4xl:ml-16;
  }

  .titleText {
    @apply font-bebas-book whitespace-nowrap;
    @apply 2xl:text-[6.5rem] 2xl:leading-[5rem] 2xl:tracking-[0.2rem];
    @apply 4xl:text-[13rem] 4xl:leading-[11rem] 4xl:tracking-[0.41rem];
  }

  .subtitleText {
    @apply font-bebas-book;
    @apply 2xl:text-[4rem] 2xl:leading-[4rem] 2xl:tracking-[0.1rem];
    @apply 4xl:text-[8rem] 4xl:leading-[8rem] 4xl:tracking-[0.21rem];
  }
}

.qrImage {
  margin: auto;
}
@media (min-width: 1536px) {
  .qrImage {
    height: 9.75rem;
    width: 9.75rem;
  }
}
@media (min-width: 3000px) {
  .qrImage {
    height: 20.75rem;
    width: 20.75rem;
  }
}

.image {
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 1;
}

.leftColumnFade {
  animation: leftColumnFade 30s linear forwards;
}

@keyframes leftColumnFade {
  47% {
    opacity: 1;
  }
  49% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  53% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.rightColumnFade {
  opacity: 1;
  animation: rightColumnFade 30s linear forwards;
}

@keyframes rightColumnFade {
  48% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  53% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.footerSlideBottom {
  transform: translateY(200%);
  animation: bottomSlide 0.7s linear 1s forwards;
}

@keyframes bottomSlide {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

.footerSlideRight {
  transform: translateX(200%);
  animation: rightSlide 0.9s ease-out 0.3s forwards;
}

@keyframes rightSlide {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}
