@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .wrapper {
    
  }

  .imageContainer {
    @apply relative;
  }
  
  .image {
    width: 100%;
    height: 70vh;
  }

  .textContainer {

  }

  .generalTitleSubtitleImageTextHeader {
    @apply font-bebas-bold uppercase text-white;
    @apply xl:pt-25 xl:ml-10 xl:mr-40 xl:text-5.625rem xl:leading-relaxed xl:tracking-tight;
    @apply 2xl:pt-47 2xl:ml-20 2xl:mr-40 2xl:text-9xl 2xl:leading-extra-loose 2xl:tracking-wider;
  }

  .tipsText {
    @apply font-bebas-regular uppercase text-white;
    @apply xl:pt-10 xl:ml-23 xl:mr-40 xl:text-5.625rem xl:leading-relaxed xl:tracking-tight;
    @apply 2xl:pt-10 2xl:ml-45 2xl:mr-40 2xl:text-9xl 2xl:leading-extra-loose 2xl:tracking-wider;
  }

  .tipsTextHeader {
    @apply font-bebas-bold uppercase text-white;
    @apply xl:pt-25 xl:ml-23 xl:mr-40 xl:text-5.625rem xl:leading-relaxed xl:tracking-tight;
    @apply 2xl:pt-47 2xl:ml-45 2xl:mr-40 2xl:text-9xl 2xl:leading-extra-loose 2xl:tracking-wider;
  }
 
  .dash {
    @apply bg-white;
    @apply xl:mt-10 xl:ml-23 xl:h-4 xl:w-20;
    @apply 2xl:mt-10 2xl:ml-45 2xl:h-8 2xl:w-40;
  }
}

.textSlide {
  transform: translateX(-100%);
  animation: slide 0.6s linear 0.8s forwards;
}

@keyframes slide {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0)
  }
}

.imageReveal {
  position: absolute; 
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  animation: reveal 0.8s linear 0.4s forwards;
}

@keyframes reveal {
  0% {
    transform: translateY(0%)
  }
  100% {
    transform: translateY(100%)
  }
}