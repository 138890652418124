@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .header {
    @apply 2xl:pt-16 xl:ml-22;
    @apply 4xl:pt-32 4xl:ml-45;
  }

  .headerText {
    @apply font-bebas-bold uppercase;
    @apply 2xl:text-6.25rem 2xl:tracking-normal 2xl:h-25 2xl:leading-6.25rem;
    @apply 4xl:text-12.5rem 4xl:tracking-widest 4xl:h-50 4xl:leading-loose;
  }
}

.headerTextReveal {
  position: relative;
  overflow: hidden;
}

.headerTextReveal::before,
.headerTextReveal::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: black;
}

.headerTextReveal::before {
  animation: reveal 2.2s ease forwards;
}
.headerTextReveal::after {
  animation: base 2.2s ease forwards;
}

@keyframes reveal {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(111%);
  }
}

@keyframes base {
  0% {
    transform: translateX(-0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.headerTextSlide {
  transform: translateX(-35%);
  animation: slide 0.5s linear forwards;
}

@keyframes slide {
  0% {
    transform: translateX(-35%)
  }
  100% {
    transform: translateX(0)
  }
}