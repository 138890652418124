@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .questionCard {
    @apply overflow-hidden bg-white;
    @apply xl:pt-22 xl:pr-22 xl:pb-14 xl:pl-13 xl:mt-12 xl:mx-8 xl:rounded-2rem;
    @apply 2xl:pt-45 2xl:pr-25 2xl:pb-28 2xl:pl-27 2xl:mt-22 2xl:mx-16 2xl:rounded-3.75rem;
  }
  .questionText {
    @apply font-bebas-regular uppercase text-black;
    @apply xl:mb-16 xl:text-6.25rem xl:tracking-tight xl:leading-relaxed;
    @apply 2xl:mb-27 2xl:text-12.5rem 2xl:tracking-wider 2xl:leading-extra-loose;
  }
  .answers{}
}

.questionCardSlide {
  transform: translateX(-110%);
  animation: questionCardSlide 0.5s ease-out 0.5s forwards;
}

@keyframes questionCardSlide {
  0% {
    transform: translateX(-110%)
  }
  100% {
    transform: translateX(0)
  }
}
