@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .backgroundImage {   
    @apply xl:h-[450px];
    @apply 1080p:h-[559px];
    @apply 2xl:h-[820px];
  }
 .comparisonContainer {
   @apply xl:px-[3.875rem];
   @apply 2xl:px-[9.063rem];
 }
  .titleContainer{
    @apply flex items-center;
    @apply xl:pb-[6.688rem];
    @apply 1080p:pb-[10rem];
    @apply 2xl:pb-[22rem] 2xl:pt-32;
  }
   .title {
     @apply absolute text-orange-usage font-bebas-bold;
     @apply xl:text-[4.7rem] xl:tracking-[0.431rem];
     @apply 1080p:text-[5.75rem] 1080p:tracking-[0.431rem];
     @apply 2xl:text-[11.25rem] 2xl:tracking-[0.844rem];
   }
   .percentageDescription {
     @apply font-bebas-bold uppercase text-white;
     @apply xl:text-[5.8rem] xl:tracking-[0.359rem] xl:leading-relaxed;
     @apply 1080p:text-[7.188rem] 1080p:tracking-[0.359rem] 1080p:leading-[9.375rem];
     @apply 2xl:text-[13.75rem] 2xl:tracking-[0.688rem] 2xl:leading-[18rem];
   }
   .progressBarFirst {
     @apply xl:pt-[8rem];
   }
   .progressBarContainer {
     @apply flex justify-center;
     @apply xl:pb-[1.375rem] xl:mx-8;
     @apply 1080p:pb-[2rem] 1080p:mx-8;
     @apply 2xl:mx-16;
   }
   .progressBar {
     @apply w-[100%] bg-progress-bar;
     @apply xl:p-2;
     @apply 2xl:p-6;
   }
   .progressBarSecond{
    @apply xl:pt-[11.5rem];
    @apply 1080p:pt-[13.75rem];
    @apply 2xl:pt-[35rem] ;
  }
   .emojiIcon {
    @apply xl:w-[220px] xl:h-[220px];
    @apply 1080p:w-[256px] 1080p:h-[256px];
    @apply 2xl:w-[433px] 2xl:h-[433px];
  }
  .emojiContainer {
    @apply absolute;
    @apply xl:right-10 xl:bottom-[45rem];
    @apply 1080p:right-10 1080p:bottom-[24rem];
    @apply 2xl:right-14 2xl:bottom-[50rem];

  }
  .padding {
      @apply xl:pt-[2rem];
      @apply 1080p:pt-[3rem];
      @apply 2xl:pt-[11rem];
    }
}
.headerTextSlide{
  overflow: hidden;
  white-space: nowrap;
  animation: 
    type 2s forwards;
  width: 0;
  animation-delay: 1.5s; 
}
.imageSlide { 
  transform: translateY(-200%);
  animation: slideDown 1.5s linear forwards;
}
@keyframes slideDown {
  0% {
    transform: translateY(-220%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
  .firstDescription {
    opacity: 0;
    animation: fadein 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }
  .secondDescription {
    transform: translateX(-110%);
    opacity: 1;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 4s;
    animation: slideRight 1.5s linear 0.4s forwards;
    animation-delay: 0.5s;
  }
  .emojiSlide {
    opacity: 0;
    transform: translateX(-50%);
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 5s;
    animation: slideLeft 1.5s linear 0.4s forwards;
    animation-delay: 0.5s;
  }
  .progressAnimation {
    transition: 1s ease;
    transition-delay: 0.5s;
  }
  .progressBarAnimation {
    transform: translateX(-110%);
    opacity: 1;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    animation: slideRight 1.5s linear 0.4s forwards;
    animation-delay: 0.2s;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(-110%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(0);
      opacity: 0;
    }
    100% {
      transform: translateX(-20%);
      opacity: 1;
    }
  }
  @keyframes fadein {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  @keyframes type { 
    from { 
      width: 0; 
      } 
    to { width: 80vw }
  }