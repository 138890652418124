@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .backgroundImage {
    @apply 3xl:h-[340px];
    @apply 4xl:h-[550px];
    @apply 3840p:h-[600px];
  }
 .comparisonContainer {
     @apply 3xl:px-20;
     @apply 4xl:px-44;
     @apply 3840p:px-44;

 }
  .titleContainer{
    @apply flex items-center pb-20;
    @apply 4xl:pb-32  4xl:pt-10;
    @apply 3840p:pb-32  3840p:pt-7;
  }
   .title {
     @apply absolute text-orange-usage font-bebas-bold;
     @apply 3xl:text-[5.75rem] 3xl:tracking-[0.431rem];
     @apply 4xl:text-[9.5rem] 4xl:tracking-[0.6rem];
     @apply 3840p:text-[11.25rem] 3840p:tracking-[0.8rem];
  }
   .percentageDescription {
     @apply font-bebas-bold uppercase text-white;
     @apply 3xl:text-[7.563rem] 3xl:tracking-[0.378rem];
     @apply 4xl:text-[12rem] 4xl:tracking-[0.85rem] 4xl:my-0;  
     @apply 3840p:text-[16.25rem] 3840p:tracking-[0.85rem] 3840p:my-0 3840p:leading-[22rem];    

   }
   .percentageNumber {
    @apply font-bebas-bold uppercase;
    @apply 3xl:text-[7.563rem] 3xl:tracking-[0.378rem];
    @apply 4xl:text-[12rem] 4xl:tracking-[0.85rem];    
    @apply 3840p:text-[16.25rem] 3840p:tracking-[0.85rem];    
  }
   .progressBarFirst {
    @apply 3xl:pt-2;
    @apply 4xl:pt-32;
    @apply 3840p:pt-32;
  }
  .progressBarContainer {
    @apply flex justify-center px-20;
    @apply 3xl:pb-7;
    @apply 4xl:px-44;
    @apply 3840p:px-44;
  }
  .progressBar {
    @apply w-[100%] bg-progress-bar p-4;
    @apply 3xl:p-1;
    @apply 4xl:p-2;
  }
  .progressBarSecond{
    @apply 3840p:pt-32;
  }
  .emojiIcon {
    @apply 3xl:w-[180px] 3xl:h-[180px];
    @apply 4xl:w-[300px] 4xl:h-[300px];
    @apply 3840p:w-[375px] 3840p:h-[375px];
  }
  .emojiContainer {
    @apply absolute right-30;
    @apply 3xl:bottom-0 3xl:top-[18rem];
    @apply 4xl:bottom-0 4xl:top-[30rem];
    @apply 3840p:bottom-0 3840p:top-[30rem];
  }
}

.headerTextSlide{
  overflow: hidden;
  white-space: nowrap;
  animation: 
    type 2s forwards;
  width: 0;
  animation-delay: 1.5s;
}
.imageSlide { 
  transform: translateY(-200%);
  animation: slideDown 1.5s linear forwards;
}
@keyframes slideDown {
  0% {
    transform: translateY(-220%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
  .firstDescription {
    opacity: 0;
    animation: fadein 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }
  .secondDescription {
    transform: translateX(-110%);
    opacity: 1;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 4s;
    animation: slideRight 1.5s linear 0.4s forwards;
    animation-delay: 0.5s;
  }
  .emojiSlide {
    opacity: 0;
    transform: translateX(-50%);
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 5s;
    animation: slideLeft 1.5s linear 0.4s forwards;
    animation-delay: 0.5s;
  }
  .progressBarAnimation {
    transform: translateX(-110%);
    opacity: 1;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    animation: slideRight 1.5s linear 0.4s forwards;
    animation-delay: 0.2s;
  }

@keyframes slideRight {
  0% {
    transform: translateX(-110%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(-50%);
    opacity: 1;
  }
}
  @keyframes fadein {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  @keyframes type { 
    from { 
      width: 0; 
      } 
    to { width: 100% }
  }